import { signInWithGooglePopup, signInWithGoogleRedirect, auth, signInUserWithEmailAndPassword } from '../utils/firebase';
import { getRedirectResult } from 'firebase/auth';
import { useEffect, useState } from 'react';
import {useNavigate} from 'react-router-dom';
import FormInput from '../components/form-input';
import { baseEmail } from '../utils/utils';

const defaultFormFields = {
    username: '',
    password: '',
}

const SignIn = () => {
    const navigate = useNavigate();
    const [formFields, setFormFields] = useState(defaultFormFields);
    const { username, password} = formFields;

    const resetFormFields = () => {
        setFormFields(defaultFormFields);
    }
    const handleChange = (event) => {
        const { id, value } = event.target;

        setFormFields({...formFields, [id]: value});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        let pass = password;
        if (pass === "temporarypassword") {
            pass = "securednapassword";
        }

        try {
            await signInUserWithEmailAndPassword(username + baseEmail, pass);
            resetFormFields();
            
            navigate('/');
        } catch (error) {
            switch(error.code) {
                case "auth/wrong-password":
                    alert("Incorrect password");
                    break;
                case "auth/user-not-found":
                    alert("User not found");
                    break;
                default:
                    console.log("Sign in failed: ", error.message);
            }
        }
    }


    return (
        <div className='column'>
            <div className='columns mt-6'>
                <div className='column is-4 is-offset-4 mt-6'>
                    <h2 className='is-size-2 has-text-weight-bold mt-6'>Sign in</h2>
                    <p className='block'>Type in your credentials</p>
                        <form onSubmit={handleSubmit}>
                            <FormInput type="text" required id="username" value={username} onChange={handleChange} label="Username" additionalClasses="block"/>
                            <FormInput type="password" required id="password" value={password} onChange={handleChange} label="Password" additionalClasses="block"/> 
                            <button type="submit" className="is-large is-fullwidth button is-info block">Sign in</button>
                        </form>
                </div>
            </div>
        </div>
    )
}

export default SignIn;