import { useState, useEffect, useContext, Fragment } from 'react';
import { getContestants, getJudges } from '../utils/firebase';
import { UserContext } from "../context/user-context";
import { useParams, useNavigate } from 'react-router-dom'
import StreetDancingItem from '../components/street-dancing-item';
import { categories, getCategoryPercentage, getCategoryName, getJudgesCount } from '../utils/utils';
import html2canvas from 'html2canvas';

const StreetDancingOverall = () => {
    const [list, setList = () => []] = useState([]);
    const [judges, setJudges = () => []] = useState([]);
    const { currentUser } = useContext(UserContext);
    const navigate = useNavigate();
    const { category } = useParams();
    const [selected, setSelected] = useState([]);
    const path = "DB/StreetDancing/Contestants";

    useEffect(() => {
        async function getDocs() {
            const docs = await getContestants(path, "score");
            setList(docs);
        };
        getDocs();
    }, []);

    useEffect(() => {
        async function getDocs() {
            const docs = await getJudges();
            setJudges(docs);
        };
        getDocs();
    }, []);

    const onItemAddHandler = async(item) => {
        const existing = selected.find((sel) => sel.id === item.id);
        if (existing) {
            setSelected(selected.filter((sel) => {return sel.id !== item.id}));
            return;
        }

        if (selected.length >= 1) {
            return;
        }

        selected.push(item);
        setSelected([...selected]);
    }

    const onPrintHandler = () => {
        var printContents = document.getElementById("print");
    
        html2canvas(printContents, {scale: 3}).then(function(canvas) {
          var myImage = canvas.toDataURL('image/png');
    
          var mywindow = window.open('PRINT');
    
          mywindow.document.write('<html><head><title>' + document.title  + '</title>');
          mywindow.document.write('</head><body >');
          mywindow.document.write('<img src="' + myImage + '" style="width: 100%"/>');
          mywindow.document.write('</body></html>');
      
          setTimeout(() => {
            mywindow.document.close();
            mywindow.focus();
        
            mywindow.print();
            mywindow.close();
          }, 10)
        })
      }

    return (
        <div className='column is-12'>
            <div id='print'>
            <p className='is-size-1 has-text-weight-bold has-text-centered'>Street Dancing</p>
            <p className='is-size-3 has-text-centered'>Competition</p>

            <div className="table-container mt-6">
                <table className="table is-fullwidth is-hoverable is-bordered has-text-centered is-selectable">
                    <thead>
                        <tr className='has-text-vcentered'>
                            <th rowSpan="3">#</th>
                            <th className='has-text-left' rowSpan="3">Name</th>
                            <th colSpan={getJudgesCount() * categories.length}>Judges</th>
                            {categories.map((category, index) => {
                                return (
                                    <th key={index + "_" + category} rowSpan="2" colSpan="3">{category}<br/><small>{getCategoryName(category)}<br/>({getCategoryPercentage(category)}%)</small></th>
                                );
                            })}
                            <th rowSpan="3">Total %</th>
                            <th rowSpan="3">Rank</th>
                        </tr>

                        <tr>
                            <th colSpan={categories.length}>1</th>
                            <th colSpan={categories.length}>2</th>
                            <th colSpan={categories.length}>3</th>
                            <th colSpan={categories.length}>4</th>
                            <th colSpan={categories.length}>5</th>
                        </tr>

                        <tr>
                        {categories.map((category, index) => {
                                return (
                                    <Fragment key={index + "_" + category + "_cats"}>
                                    <td>C1</td>
                                    <td>C2</td>
                                    <td>C3</td>
                                    <td>C4</td>
                                    <td>C5</td>
                                    </Fragment>
                                );
                            })}
                            {categories.map((category, index) => {

                                return (
                                    <Fragment key={index + "_" + category + "_data"}>
                                        <th>T</th>
                                        <th>A</th>
                                        <th>%</th>
                                    </Fragment>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((contestant, index) => {
                            var tie = 0;
                            for (let i = index - 1; i > -1; i--) {
                                if (list[i].score === contestant.score) {
                                    tie++;
                                }
                            }
                            const pos = index + 1 - tie;
                            const active = selected.find((sel) => sel.id === contestant.id);

                            return (
                                <StreetDancingItem rank={pos} active={active} contestant={contestant} key={contestant.id} onItemAddHandler={onItemAddHandler}/>
                            )
                        })}
                    </tbody>
                    </table>
                </div>

                <div className='columns my-6'>
                    {judges.map((judge, index) => {
                        return (
                            <div className='column is-one-fifth' key={judge.uid}><p className='has-text-centered has-text-weight-medium is-size-5' style={{borderBottom: "1px solid #dbdbdb"}}>{judge.name}</p><p className='has-text-centered'>{judge.uid.charAt(0).toUpperCase() + judge.uid.substring(1).toLowerCase()}</p></div>
                        )
                    })}
                </div>
                </div>
                    <button type="button" className="is-fullwidth button is-info block is-large column is-8 is-offset-2 my-6" onClick={onPrintHandler}>Print</button>
        </div>
    )
}

export default StreetDancingOverall;