import { getCategoryPercentage, getJudgesCount } from "../utils/utils";

const StreetDancingItem = ({contestant, rank, onItemAddHandler, active}) => {
    const {name, number, id, municipality,
        judge1_sd_c1, judge1_sd_c2, judge1_sd_c3, judge1_sd_c4, judge1_sd_c5,
        judge2_sd_c1, judge2_sd_c2, judge2_sd_c3, judge2_sd_c4, judge2_sd_c5,
        judge3_sd_c1, judge3_sd_c2, judge3_sd_c3, judge3_sd_c4, judge3_sd_c5,
        judge4_sd_c1, judge4_sd_c2, judge4_sd_c3, judge4_sd_c4, judge4_sd_c5,
        judge5_sd_c1, judge5_sd_c2, judge5_sd_c3, judge5_sd_c4, judge5_sd_c5,
    } = contestant;

    const c1 = judge1_sd_c1 + judge2_sd_c1 + judge3_sd_c1 + judge4_sd_c1 + judge5_sd_c1;
    const c2 = judge1_sd_c2 + judge2_sd_c2 + judge3_sd_c2 + judge4_sd_c2 + judge5_sd_c2;
    const c3 = judge1_sd_c3 + judge2_sd_c3 + judge3_sd_c3 + judge4_sd_c3 + judge5_sd_c3;
    const c4 = judge1_sd_c4 + judge2_sd_c4 + judge3_sd_c4 + judge4_sd_c4 + judge5_sd_c4;
    const c5 = judge1_sd_c5 + judge2_sd_c5 + judge3_sd_c5 + judge4_sd_c5 + judge5_sd_c5;
    
    const count = getJudgesCount();
    const c1a = c1 / count;
    const c2a = c2 / count;
    const c3a = c3 / count;
    const c4a = c4 / count;
    const c5a = c5 / count;

    const c1p = (c1a * getCategoryPercentage("C1")) / 10;
    const c2p = (c2a * getCategoryPercentage("C2")) / 10;
    const c3p = (c3a * getCategoryPercentage("C3")) / 10;
    const c4p = (c4a * getCategoryPercentage("C4")) / 10;
    const c5p = (c5a * getCategoryPercentage("C5")) / 10;

    const tot = c1p + c2p + c3p + c4p + c5p;

    contestant["score"] = tot;

    return (
        <tr className={`is-size-6 has-text-vcentered ${active ? "is-active" : ""}`} onClick={() => {onItemAddHandler ? onItemAddHandler({name, number, id}) : console.log("")}}>
            <td>{number}</td>
            <td className='is-size-6 has-text-left'><p className="is-size-5 has-text-weight-medium ">{name}</p>{municipality}</td>
            <td>{judge1_sd_c1}</td>
            <td>{judge1_sd_c2}</td>
            <td>{judge1_sd_c3}</td>
            <td>{judge1_sd_c4}</td>
            <td>{judge1_sd_c5}</td>

            <td>{judge2_sd_c1}</td>
            <td>{judge2_sd_c2}</td>
            <td>{judge2_sd_c3}</td>
            <td>{judge2_sd_c4}</td>
            <td>{judge2_sd_c5}</td>

            <td>{judge3_sd_c1}</td>
            <td>{judge3_sd_c2}</td>
            <td>{judge3_sd_c3}</td>
            <td>{judge3_sd_c4}</td>
            <td>{judge3_sd_c5}</td>

            <td>{judge4_sd_c1}</td>
            <td>{judge4_sd_c2}</td>
            <td>{judge4_sd_c3}</td>
            <td>{judge4_sd_c4}</td>
            <td>{judge4_sd_c5}</td>

            <td>{judge5_sd_c1}</td>
            <td>{judge5_sd_c2}</td>
            <td>{judge5_sd_c3}</td>
            <td>{judge5_sd_c4}</td>
            <td>{judge5_sd_c5}</td>

            <td>{c1 ? c1.toFixed(2) : ""}</td>
            <td>{c1a ? c1a.toFixed(2) : ""}</td>
            <td>{c1p ? c1p.toFixed(2) + "%" : ""}</td>
            <td>{c2 ? c2.toFixed(2) : ""}</td>
            <td>{c2a ? c2a.toFixed(2) : ""}</td>
            <td>{c2p ? c2p.toFixed(2) + "%" : ""}</td>
            <td>{c3 ? c3.toFixed(2) : ""}</td>
            <td>{c3a ? c3a.toFixed(2) : ""}</td>
            <td>{c3p ? c3p.toFixed(2) + "%" : ""}</td>
            <td>{c4 ? c4.toFixed(2) : ""}</td>
            <td>{c4a ? c4a.toFixed(2) : ""}</td>
            <td>{c4p ? c4p.toFixed(2) + "%" : ""}</td>
            <td>{c5 ? c5.toFixed(2) : ""}</td>
            <td>{c5a ? c5a.toFixed(2) : ""}</td>
            <td>{c5p ? c5p.toFixed(2) + "%" : ""}</td>

            <td>{tot ? tot.toFixed(2) + "%" : ""}</td>
                {rank ? <td>{rank}</td> : ""}
        </tr>
    )
}

export default StreetDancingItem;