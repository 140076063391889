
const FormInput = ({ label, additionalClasses, error, ...otherProps }) => {
    return (
        <div className={`${additionalClasses ? additionalClasses : ""}`}>
        {label && (
            <label className="is-size-4">{label}</label>
        )}
        <input {...otherProps} className="input is-large" onWheel={(e) => {e.target.blur()}}/>
        {error && (
            <label className="has-text-danger">{error}</label>
        )}
        </div>
    );
}

export default FormInput;