import { useState, useEffect, useContext, Fragment } from 'react';
import { getContestants, resetContestants, setContestantsScore } from '../utils/firebase';
import { UserContext } from "../context/user-context";
import { useParams, useNavigate } from 'react-router-dom'
import StreetDancingItem from '../components/street-dancing-item';
import { categories, getCategoryPercentage, getCategoryName, getJudgesCount } from '../utils/utils';

const StreetDancingResults = () => {
    const [list, setList = () => []] = useState([]);
    const { currentUser } = useContext(UserContext);
    const navigate = useNavigate();
    const { category } = useParams();
    const path = "DB/StreetDancing/Contestants";

    useEffect(() => {
        async function getDocs() {
            const docs = await getContestants(path, "number");
            setList(docs);
        };
        getDocs();
    }, []);

    const onClickHandler = async() => {
        for (const item of list) {
            if (!item.score) {
                alert("Insufficient data");
                return;
            }
        }

        const response = await setContestantsScore(path, list);

        if (response === "success") {
            navigate("/street-dancing/overall");
        } else {
            alert(response);
        }
    }

    const reset = async() => {
        const response = await resetContestants(path, list);
        if (response === "success") {
            navigate("/");
        } else {
            alert(response);
        }
    }

    const onAlert = () => {
        const person = prompt("Confirm Password", "");
        if (person === "secretresetpass") {
            reset();
        } else {
            alert("Incorrect Password");
        }
    }

    return (
        <div className='column is-12'>
            <p className='is-size-1 has-text-weight-bold has-text-centered'>Street Dancing</p>
            <p className='is-size-3 has-text-centered'>Competition</p>

            <div className="table-container mt-6">
                <table className="table is-fullwidth is-hoverable is-bordered has-text-centered">
                    <thead>
                        <tr className='has-text-vcentered'>
                            <th rowSpan="3">#</th>
                            <th className='has-text-left' rowSpan="3">Name</th>
                            <th colSpan={getJudgesCount() * categories.length}>Judges</th>
                            {categories.map((category, index) => {
                                return (
                                    <th key={index + "_" + category} rowSpan="2" colSpan="3">{category}<br/><small>{getCategoryName(category)}<br/>({getCategoryPercentage(category)}%)</small></th>
                                );
                            })}
                            <th rowSpan="3">Total %</th>
                        </tr>

                        <tr>
                            <th colSpan={categories.length}>1</th>
                            <th colSpan={categories.length}>2</th>
                            <th colSpan={categories.length}>3</th>
                            <th colSpan={categories.length}>4</th>
                            <th colSpan={categories.length}>5</th>
                        </tr>

                        <tr>
                        {categories.map((category, index) => {
                                return (
                                    <Fragment key={index + "_" + category + "_cats"}>
                                    <td>C1</td>
                                    <td>C2</td>
                                    <td>C3</td>
                                    <td>C4</td>
                                    <td>C5</td>
                                    </Fragment>
                                );
                            })}
                            {categories.map((category, index) => {

                                return (
                                    <Fragment key={index + "_" + category + "_data"}>
                                        <th>T</th>
                                        <th>A</th>
                                        <th>%</th>
                                    </Fragment>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((contestant, index) => {
                            return (
                                <StreetDancingItem contestant={contestant} key={contestant.id}/>
                            )
                        })}
                    </tbody>
                    </table>
                </div>
                    <button type="button" className="is-fullwidth button is-info block is-large column is-8 is-offset-2 my-6" onClick={onClickHandler}>Finalize</button>

                    <button type="button" className="is-fullwidth button is-danger block is-large column is-8 is-offset-2 mb-6" onClick={onAlert}>Reset</button>
        </div>
    )
}

export default StreetDancingResults;