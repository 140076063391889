import { useState, useEffect, useContext } from 'react';
import { getDocument, updateDocument } from '../utils/firebase';
import { UserContext } from "../context/user-context";
import { useNavigate, useParams } from 'react-router-dom'
import FormInput from '../components/form-input'
import { getCategoryPercentage } from '../utils/utils';

const defaultDoc = {
    name: "",
    municipality: "",
    number: "",
};

const StreetDancingScoring = () => {
    const [doc, setDoc] = useState(defaultDoc);
    const { currentUser } = useContext(UserContext);
    const navigate = useNavigate();
    const { id } = useParams();
    const {sd_locked: locked} = currentUser.data;

    const c1i = currentUser.data.uid + "_sd_c1";
    const c2i = currentUser.data.uid + "_sd_c2";
    const c3i = currentUser.data.uid + "_sd_c3";
    const c4i = currentUser.data.uid + "_sd_c4";
    const c5i = currentUser.data.uid + "_sd_c5";
    
    const [c1, setC1] = useState("");
    const [c2, setC2] = useState("");
    const [c3, setC3] = useState("");
    const [c4, setC4] = useState("");
    const [c5, setC5] = useState("");

    const {name, municipality, number} = doc;
    const docPath = `DB/StreetDancing/Contestants/${id}`;

    useEffect(() => {
        async function getDocs() {
            const res = await getDocument(docPath);
            if (res) {
                setDoc(res);

                setC1(res[c1i].toString());
                setC2(res[c2i].toString());
                setC3(res[c3i].toString());
                setC4(res[c4i].toString());
                setC5(res[c5i].toString());
            }
        };
        getDocs();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const sd_c1 = parseFloat(parseFloat(c1).toFixed(2));
        const sd_c2 = parseFloat(parseFloat(c2).toFixed(2));
        const sd_c3 = parseFloat(parseFloat(c3).toFixed(2));
        const sd_c4 = parseFloat(parseFloat(c4).toFixed(2));
        const sd_c5 = parseFloat(parseFloat(c5).toFixed(2));
        const sd_total = (sd_c1 * getCategoryPercentage("C1")) +
        (sd_c2 * getCategoryPercentage("C2")) +
        (sd_c3 * getCategoryPercentage("C3")) +
        (sd_c4 * getCategoryPercentage("C4")) +
        (sd_c5 * getCategoryPercentage("C5"));

        const data = {
            [c1i]: sd_c1,
            [c2i]: sd_c2,
            [c3i]: sd_c3,
            [c4i]: sd_c4,
            [c5i]: sd_c5,
            [currentUser.data.uid + "_sd_total"]: parseFloat((sd_total / 10).toFixed(2)),
        }

        const res = await updateDocument(docPath, data);
        if (res === "success") {
            navigate("/street-dancing");
        }

        // TODO add lock
    }

    return (
        <div className='column is-8 is-offset-2'>
        <p className='is-size-1 has-text-weight-bold has-text-centered'>Street Dancing</p>
        <p className='is-size-3 has-text-centered'>Competition</p>

            <form onSubmit={handleSubmit}>
                <div className='columns my-6'>
                    <div className="column is-8 is-offset-2">
                        <div className="card">
                            <div className="card-content">
                                <div className="media">
                                    <div className="media-content p-4">
                                        <p className="title is-size-1 has-text-centered pt-4">{number ? `#${number}` : ""}</p>
                                        <p className="title is-size-1 has-text-centered">{name}</p>
                                        <p className="is-size-3 has-text-centered pb-4">{municipality}</p>
                                        
                                        <FormInput disabled={locked} additionalClasses="mt-6" type="number" required value={c1} onChange={(e) => setC1(e.target.value)} step="0.01" min="1" max="10" label="Choreography — 25%"/>
                                        <FormInput disabled={locked} additionalClasses="my-4" type="number" required value={c2} onChange={(e) => setC2(e.target.value)} step="0.01" min="1" max="10" label="Costume & Props — 20%"/>
                                        <FormInput disabled={locked} additionalClasses="my-4" type="number" required value={c3} onChange={(e) => setC3(e.target.value)} step="0.01" min="1" max="10" label="Performance — 30%"/>
                                        <FormInput disabled={locked} additionalClasses="my-4" type="number" required value={c4} onChange={(e) => setC4(e.target.value)} step="0.01" min="1" max="10" label="Theme Concept — 15%"/>
                                        <FormInput disabled={locked} additionalClasses="my-4" type="number" required value={c5} onChange={(e) => setC5(e.target.value)} step="0.01" min="1" max="10" label="Visual & Sound Impact — 10%"/>
                                        
                                        {locked ? <button type="button" className="is-fullwidth button is-info block is-large column is-12 mt-6" onClick={() => {navigate("/street-dancing")}}>Go Back</button> : 
                                            <button type="submit" className="is-fullwidth button is-info block is-large mt-6">Submit</button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default StreetDancingScoring;