import { useState, useEffect, useContext, Fragment } from 'react';
import { getContestants, setLocked } from '../utils/firebase';
import { UserContext } from "../context/user-context";
import { useNavigate, useLocation } from 'react-router-dom'

const StreetDancingRanking = () => {
    const [list, setList = () => []] = useState([]);
    const { currentUser, setCurrentUser } = useContext(UserContext);
    const navigate = useNavigate();
    const key = currentUser.data.uid + "_sd_total";
    const c1i = currentUser.data.uid + "_sd_c1";
    const c2i = currentUser.data.uid + "_sd_c2";
    const c3i = currentUser.data.uid + "_sd_c3";
    const c4i = currentUser.data.uid + "_sd_c4";
    const c5i = currentUser.data.uid + "_sd_c5";
    const {sd_locked: locked} = currentUser.data;

    useEffect(() => {
        async function getDocs() {
            const docs = await getContestants("DB/StreetDancing/Contestants", key);
            setList(docs);
        };
        getDocs();
    }, []);

    const onClickHandler = async() => {
        const lock = await setLocked(currentUser.uid, "sd_locked");
        if (lock === "success") {
            setCurrentUser({
                ...currentUser,
                data: {
                    ...currentUser.data,
                    "sd_locked": true,
                }
            });
            navigate("/");
        } else {
            alert(lock);
        }
    }

    const alertDialog = () => {
        if (list.length < 8) {
            alert("Incomplete data");
            return;
        }
        var res = window.confirm("Are you sure you want to finalize rating?");
        if (res) {onClickHandler();}
    }

    return (
        <div className='column is-10 is-offset-1 px-6'>
            <p className='is-size-1 has-text-weight-bold has-text-centered'>Street Dancing</p>
            <p className='is-size-3 has-text-centered'>Competition</p>

            <div className="table-container mt-6">
                <table className="table is-fullwidth is-hoverable is-borderless is-size-5">
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th className='has-text-left'>Festival Name</th>
                            <th>Choreography</th>
                            <th>Costume & Props</th>
                            <th>Performance</th>
                            <th>Theme Concept</th>
                            <th>Visual & Sound Impact</th>
                            <th>Total %</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((contestant, index) => {
                            var tie = 0;
                            for (let i = index - 1; i > -1; i--) {
                                if (list[i][key] === contestant[key]) {
                                    tie++;
                                }
                            }
                            const pos = index + 1 - tie;

                            const {name, municipality, number} = contestant;
                            const c1 = contestant[c1i] ?? 0;
                            const c2 = contestant[c2i] ?? 0;
                            const c3 = contestant[c3i] ?? 0;
                            const c4 = contestant[c4i] ?? 0;
                            const c5 = contestant[c5i] ?? 0;
                            const score = contestant[key] ?? 0;
                            return (
                                <tr className='is-size-5 has-text-vcentered' key={contestant.id}>
                                    <td>{pos}</td>
                                    <td><p className='has-text-left'>
                                        <span className='has-text-weight-medium is-size-4'>#{number} {name}</span><br/>{municipality}
                                    </p></td>
                                    <td>{c1}</td>
                                    <td>{c2}</td>
                                    <td>{c3}</td>
                                    <td>{c4}</td>
                                    <td>{c5}</td>
                                    <td>{score.toFixed(2)}%</td>
                                </tr>
                            )
                        })}
                    </tbody>
                    </table>
                </div>
                <div className='columns my-6'>
                {locked ? <button type="button" className="is-fullwidth button is-info block is-large column is-12" onClick={() => {navigate("/")}}>Return to Home</button> : 
                    <button type="button" disabled={list.length < 8} className="is-fullwidth button is-danger block is-large column is-12" onClick={() => {
                        if (list.length < 8) {
                            // do nothing
                        } else {
                            alertDialog();
                        }
                    }}>Finalize</button>}
                </div>
        </div>
    )
}

export default StreetDancingRanking;