import { useState, useEffect, useContext, Fragment } from 'react';
import { getContestants, setLocked } from '../utils/firebase';
import { UserContext } from "../context/user-context";
import { useNavigate, useLocation } from 'react-router-dom'

const StreetDancing = () => {
    const [list, setList = () => []] = useState([]);
    const { currentUser } = useContext(UserContext);
    const navigate = useNavigate();
    const key = currentUser.data.uid + "_sd_total";
    const { state } = useLocation();

    useEffect(() => {
        async function getDocs() {
            const docs = await getContestants("DB/StreetDancing/Contestants", "number");
            setList(docs);
        };
        getDocs();
    }, []);

    return (
        <div className='column is-8 is-offset-2'>
            <p className='is-size-1 has-text-weight-bold has-text-centered'>Street Dancing</p>
            <p className='is-size-3 has-text-centered'>Competition</p>

                <div className='columns is-multiline mt-6'>
                    {list.map((contestant, index) => {
                        const number = index + 1;
                        const {name, municipality, id} = contestant;
                            return (
                            <div className="column is-4">
                                <div className="card is-clickable" onClick={() => navigate(`/street-dancing/${id}`)}>
                                    <div className="card-content">
                                        <div className="media">
                                            <div className="media-content py-6">
                                                <p className="title is-size-3 has-text-centered pt-4">#{number}</p>
                                                <p className="title is-size-3 has-text-centered">{name}</p>
                                                <p className="is-size-4 has-text-centered pb-4">{municipality}</p>
                                                <p className="is-size-4 has-text-centered pb-4"><span className={`tag is-medium ${contestant[key] ? 'is-success' : ''}`}>{contestant[key] ? 'Scored' : 'Not Scored'}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>

                <div className='columns my-6'>
                <button type="button" className="is-fullwidth button is-info block is-large column is-12" onClick={() => {navigate("/street-dancing/ranking")}}>View Ranking</button>
                </div>
        </div>
    )
}

export default StreetDancing;