import { useState, useEffect, useContext, Fragment } from 'react';
import { getContestants, resetContestants, setContestantsFQSScore } from '../utils/firebase';
import { UserContext } from "../context/user-context";
import { useParams, useNavigate } from 'react-router-dom'
import FestivalQueenItem from '../components/festival-queen-item';
import { categories, getJudgesCount } from '../utils/utils';

const FestivalQueenResults = () => {
    const [list, setList = () => []] = useState([]);
    const { currentUser } = useContext(UserContext);
    const navigate = useNavigate();
    const { category } = useParams();
    const path = "DB/StreetDancing/Contestants";

    useEffect(() => {
        async function getDocs() {
            const docs = await getContestants(path, "number");
            setList(docs);
        };
        getDocs();
    }, []);

    const onClickHandler = async() => {
        for (const item of list) {
            if (!item.fqs_score) {
                alert("Insufficient data");
                return;
            }
        }

        const response = await setContestantsFQSScore(path, list);

        if (response === "success") {
            navigate("/festival-queen/overall");
        } else {
            alert(response);
        }
    }

    const reset = async() => {
        const response = await resetContestants(path, list);
        if (response === "success") {
            navigate("/");
        } else {
            alert(response);
        }
    }

    const onAlert = () => {
        const person = prompt("Confirm Password", "");
        if (person === "secretresetpass") {
            reset();
        } else {
            alert("Incorrect Password");
        }
    }

    return (
        <div className='column is-12'>
            <p className='is-size-1 has-text-weight-bold has-text-centered'>Festival Queen</p>
            <p className='is-size-3 has-text-centered'>Showdown</p>

            <div className="table-container mt-6">
                <table className="table is-fullwidth is-hoverable is-bordered has-text-centered">
                    <thead>
                        <tr className='has-text-vcentered'>
                            <th rowSpan="2">#</th>
                            <th className='has-text-left' rowSpan="2">Name</th>
                            <th colSpan={getJudgesCount()}>Judges</th>
                            <th colSpan="3">Final Rating<br/><small>(100%)</small></th>
                        </tr>

                        <tr>
                            <th>1</th>
                            <th>2</th>
                            <th>3</th>
                            <th>4</th>
                            <th>5</th>
                            <th>T</th>
                            <th>A</th>
                            <th>%</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((contestant, index) => {
                            return (
                                <FestivalQueenItem contestant={contestant} key={contestant.id}/>
                            )
                        })}
                    </tbody>
                    </table>
                </div>
                    <button type="button" className="is-fullwidth button is-info block is-large column is-8 is-offset-2 my-6" onClick={onClickHandler}>Finalize</button>

                    <button type="button" className="is-fullwidth button is-danger block is-large column is-8 is-offset-2 mb-6" onClick={onAlert}>Reset</button>
        </div>
    )
}

export default FestivalQueenResults;