import { getCategoryPercentage, getJudgesCount } from "../utils/utils";

const FestivalQueenItem = ({contestant, rank, onItemAddHandler, active}) => {
    const {name, number, id, municipality,
        judge1_fqs, judge2_fqs, judge3_fqs, judge4_fqs, judge5_fqs,
    } = contestant;

    const c1 = judge1_fqs + judge2_fqs + judge3_fqs + judge4_fqs + judge5_fqs;
    
    const count = getJudgesCount();
    const c1a = c1 / count;

    const c1p = (c1a * 100) / 10;

    contestant["fqs_score"] = c1p;

    return (
        <tr className={`is-size-6 has-text-vcentered ${active ? "is-active" : ""}`} onClick={() => {onItemAddHandler ? onItemAddHandler({name, number, id}) : console.log("")}}>
            <td>{number}</td>
            <td className='is-size-6 has-text-left'><p className="is-size-5 has-text-weight-medium ">{name}</p>{municipality}</td>
            <td>{judge1_fqs}</td>
            <td>{judge2_fqs}</td>
            <td>{judge3_fqs}</td>
            <td>{judge4_fqs}</td>
            <td>{judge5_fqs}</td>

            <td>{c1 ? c1.toFixed(2) : ""}</td>
            <td>{c1a ? c1a.toFixed(2) : ""}</td>
            <td>{c1p ? c1p.toFixed(2) + "%" : ""}</td>

                {rank ? <td>{rank}</td> : ""}
        </tr>
    )
}

export default FestivalQueenItem;