import { useState, useEffect, useContext, Fragment } from 'react';
import { getContestants, setContestantScores, setLocked } from '../utils/firebase';
import { UserContext } from "../context/user-context";
import { useNavigate, useLocation } from 'react-router-dom'
import FormInput from '../components/form-input';

const FestivalQueen = () => {
    const [list, setList = () => []] = useState([]);
    const { currentUser } = useContext(UserContext);
    const navigate = useNavigate();
    const [scores, setScores] = useState([]);
    const key = currentUser.data.uid + "_fqs";
    const {fqs_locked: locked} = currentUser.data;

    useEffect(() => {
        async function getDocs() {
            const docs = await getContestants("DB/StreetDancing/Contestants", "number");

            const temp = [];
            for (const doc of docs) {
                temp.push(doc[key] ?? "");
            }
            setScores(temp);

            setList(docs);
        };
        getDocs();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (locked) {
            navigate("/festival-queen/ranking", {state: {locked: locked}});
            return;
        }

        const response = await setContestantScores(key, list, scores);

        if (response === "success") {
            navigate("/festival-queen/ranking");
        } else {
            alert(response);
        }
    }

    return (
        <div className='column is-8 is-offset-2'>
        <p className='is-size-1 has-text-weight-bold has-text-centered'>Festival Queen</p>
        <p className='is-size-3 has-text-centered'>Showdown</p>

            <form onSubmit={handleSubmit}>
                <div className='columns is-multiline mt-6'>
                    {list.map((contestant, index) => {
                        const number = index + 1;
                        const {name, municipality, id} = contestant;

                        return (
                            <div className="column is-4">
                                <div className="card">
                                    <div className="card-content">
                                        <div className="media">
                                            <div className="media-content py-6">
                                                <p className="title is-size-3 has-text-centered pt-4">#{number}</p>
                                                <p className="title is-size-3 has-text-centered">{name}</p>
                                                <p className="is-size-4 has-text-centered pb-4">{municipality}</p>
                                                <FormInput disabled={locked} additionalClasses="mt-6" type="number" required value={scores[index]} onChange={(e) => {
                                                    scores[index] = e.target.value;
                                                    setScores([...scores]);
                                                }} step="0.01" min="1" max="10" label="Rate"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                
                <button type="submit" className="is-fullwidth button is-info block is-large my-6">{locked ? "View Ranking" : "Submit"}</button>
            </form>
        </div>
    )
}

export default FestivalQueen;