export const baseEmail = "@streetdancing.com";

export const usersPath = "DB/StreetDancing/Users";

export const title = "-";

export const categories = ["C1", "C2", "C3", "C4", "C5"];

export const getCategoryName = (category) => {
    var txt = "";
    switch(category) {
        case "C1":
            txt = "Choreography";
            break;
        case "C2":
            txt = "Costume & Props";
            break;
        case "C3":
            txt = "Performance";
            break;
        case "C4":
            txt = "Theme Concept";
            break;
        case "C5":
            txt = "Visual & Sound Impact";
            break;
    }

    return txt;
}

export const getCategoryPercentage = (category) => {
    var per = 0;
    switch(category) {
        case "C1":
            per = 25;
            break;
        case "C2":
            per = 20;
            break;
        case "C3":
            per = 30;
            break;
        case "C4":
            per = 15;
            break;
        case "C5":
            per = 10;
            break;
    }

    return per;
}

export const getJudgesCount = (category) => {
    return 5;
}