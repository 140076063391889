import logo from '../images/logo.png'
import { Fragment, useContext, useState } from "react";
import { UserContext } from "../context/user-context";
import { signOutUser } from "../utils/firebase";
import {useNavigate} from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import { Outlet } from "react-router-dom";
import { getCategoryName } from '../utils/utils';

const Navigation = () => {
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [toggle, setToggle] = useState(false);

  const signOutHandler = () => {
    signOutUser();
    navigate('/');
  }

  const onMenuToggle = () => {
    setToggle(!toggle);
  }

  const onNavigate = (link) => {
    navigate(link);
    setToggle(false);
  }

  return (
    <Fragment>
          <nav className="navbar py-5" role="navigation" aria-label="main navigation">
  <div className="navbar-brand">
    <a className="navbar-item" onClick={() => onNavigate("/")}>
      <img src={logo}/>
      <h1 style={{width: 170 + "px"}}>
        <p className="is-size-5 has-text-weight-medium">Bantayog2024</p>
        <small>A&A Solutions</small>
        </h1>
    </a>

    <a onClick={onMenuToggle} role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  {currentUser ? <div id="navbarBasicExample" className={`navbar-menu ${toggle ? "is-active" : ""}`}>
    <div className='navbar-start'>
        <a className="navbar-item" onClick={() => {currentUser.data.type === "superadmin" ? navigate("/street-dancing/results") : navigate("/street-dancing")}}>
                Street Dancing
              </a>

            <a className="navbar-item" onClick={() => {currentUser.data.type === "superadmin" ? navigate("/festival-queen/results") : navigate("/festival-queen")}}>
              Festival Queen
            </a>

            {currentUser.data.type === "superadmin" ? <a className="navbar-item" onClick={() => navigate("/judges")}>
              Judges
            </a> : ""}
    </div>
    <div className="navbar-end">
      <div className="navbar-item">
        <span className='has-text-weight-medium'>{currentUser.data.uid.toUpperCase()}</span>
      </div>

      <div className="navbar-item">
        <div className="buttons">
          <a className="button is-info" onClick={signOutHandler}>
            Sign out
          </a>
        </div>
      </div>
    </div>
  </div> : ""}
</nav>
          <Outlet/>
    </Fragment>
  )
}

export default Navigation;
