import FormInput from './form-input'
import { getCategoryName } from '../utils/utils';
import { Fragment } from 'react';

const JudgesItem = ({judge, name, index, onValueChange}) => {
    const {uid, sd_locked, fqs_locked} = judge;

    return (
        <div className="column is-4">
            <div className="card">
                <div className="card-content">
                    <div className="media">
                        <div className="media-content">
                            <p className="title is-size-3">{uid.toUpperCase()}</p>
                            <label className="checkbox is-block">
                                <input type="checkbox" className='mr-2' checked={sd_locked}/>
                                Street  Dancing
                            </label>
                            <label className="checkbox is-block">
                                <input type="checkbox" className='mr-2' checked={fqs_locked}/>
                                Festival Queen
                            </label>
                            <FormInput additionalClasses="mt-5 mb-3" type="text" required id={index} value={name} onChange={(e) => {onValueChange(e)}} label="Full Name"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JudgesItem;