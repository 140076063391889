import './App.scss';
import 'bulma/css/bulma.min.css';
import { useContext } from 'react';
import { UserContext } from './context/user-context';
import { Routes, Route } from "react-router-dom";
import Navigation from './routes/navigation';
import Home from './routes/home';
import SignIn from './routes/siginin';
import StreetDancingRanking from './routes/street-dancing-ranking';
import StreetDancing from './routes/street-dancing';
import StreetDancingScoring from './routes/street-dancing-scoring';
import StreetDancingResults from './routes/street-dancing-results';
import StreetDancingOverall from './routes/street-dancing-overall';
import FestivalQueen from './routes/festival-queen';
import FestivalQueenRanking from './routes/festival-queen-ranking';
import FestivalQueenResults from './routes/festival-queen-results';
import FestivalQueenOverall from './routes/festival-queen-overall';
import Judges from './routes/judges';

function App() {
  const { currentUser } = useContext(UserContext);

  return (
    <Routes>
      <Route path="/" element={<Navigation/>}>
        <Route index element={currentUser ? <Home/> : <SignIn/>}/>
        <Route path='/street-dancing' element={currentUser ? <StreetDancing/> : ""}/>
        <Route path='/street-dancing/ranking' element={currentUser ? <StreetDancingRanking/> : ""}/>
        <Route path='/street-dancing/:id' element={currentUser ? <StreetDancingScoring/> : ""}/>
        <Route path='/festival-queen' element={currentUser ? <FestivalQueen/> : ""}/>
        <Route path='/festival-queen/ranking' element={currentUser ? <FestivalQueenRanking/> : ""}/>

        <Route path='/street-dancing/results' element={currentUser ? currentUser.data.type === "superadmin" ? <StreetDancingResults/> : "" : ""}/>
        <Route path='/street-dancing/overall' element={currentUser ? currentUser.data.type === "superadmin" ? <StreetDancingOverall/> : "" : ""}/>
        <Route path='/festival-queen/results' element={currentUser ? currentUser.data.type === "superadmin" ? <FestivalQueenResults/> : "" : ""}/>
        <Route path='/festival-queen/overall' element={currentUser ? currentUser.data.type === "superadmin" ? <FestivalQueenOverall/> : "" : ""}/>
        <Route path='/judges' element={currentUser ? currentUser.data.type === "superadmin" ? <Judges/> : "" : ""}/>
      </Route>
    </Routes>
  );
}

export default App;
