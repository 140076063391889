import { useContext } from 'react';
import { UserContext } from "../context/user-context";
import { useNavigate } from 'react-router-dom'
import banner from '../images/banner.jpg'

const Home = () => {
    const { currentUser } = useContext(UserContext);
    const navigate = useNavigate();

    return (
        <div className='has-text-centered'>
            <img src={banner} alt='banner' style={{width: 60 +"%"}}/>            
        </div>
    )
}

export default Home;