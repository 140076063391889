import { useState, useEffect, useContext, Fragment } from 'react';
import { getContestants, setLocked } from '../utils/firebase';
import { UserContext } from "../context/user-context";
import { useNavigate, useLocation } from 'react-router-dom'

const FestivalQueenRanking = () => {
    const [list, setList = () => []] = useState([]);
    const { currentUser, setCurrentUser } = useContext(UserContext);
    const navigate = useNavigate();
    const key = currentUser.data.uid + "_fqs";
    const {fqs_locked: locked} = currentUser.data;

    useEffect(() => {
        async function getDocs() {
            const docs = await getContestants("DB/StreetDancing/Contestants", key);
            setList(docs);
        };
        getDocs();
    }, []);

    const onClickHandler = async() => {
        const lock = await setLocked(currentUser.uid, "fqs_locked");
        if (lock === "success") {
            setCurrentUser({
                ...currentUser,
                data: {
                    ...currentUser.data,
                    "fqs_locked": true,
                }
            });
            navigate("/");
        } else {
            alert(lock);
        }
    }

    const alertDialog = () => {
        var res = window.confirm("Are you sure you want to finalize rating?");
        if (res) {onClickHandler();}
    }

    return (
        <div className='column is-10 is-offset-1 px-6'>
        <p className='is-size-1 has-text-weight-bold has-text-centered'>Festival Queen</p>
        <p className='is-size-3 has-text-centered'>Showdown</p>

            <div className="table-container mt-6">
                <table className="table is-fullwidth is-hoverable is-borderless is-size-5">
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th className='has-text-left'>Festival Name</th>
                            <th>Rating</th>
                            <th>Total %</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((contestant, index) => {
                            var tie = 0;
                            for (let i = index - 1; i > -1; i--) {
                                if (list[i][key] === contestant[key]) {
                                    tie++;
                                }
                            }
                            const pos = index + 1 - tie;

                            const {name, municipality, number} = contestant;
                            const score = contestant[key] ?? 0;
                            return (
                                <tr className='is-size-5 has-text-vcentered' key={contestant.id}>
                                    <td>{pos}</td>
                                    <td><p className='has-text-left'>
                                        <span className='has-text-weight-medium is-size-4'>#{number} {name}</span><br/>{municipality}
                                    </p></td>
                                    <td>{score.toFixed(2)}</td>
                                    <td>{(score * 10).toFixed(2)}%</td>
                                </tr>
                            )
                        })}
                    </tbody>
                    </table>
                </div>
                <div className='columns my-6'>
                {locked ? <button type="button" className="is-fullwidth button is-info block is-large column is-12" onClick={() => {navigate("/")}}>Return to Home</button> : 
                    <Fragment><button type="button" className="is-fullwidth button is-info block is-large column is-2" onClick={() => {navigate("/festival-queen/")}}>Edit</button>
                    <button type="button" className="is-fullwidth button is-danger block is-large column is-9 is-offset-1" onClick={alertDialog}>Finalize</button></Fragment>}
                </div>
        </div>
    )
}

export default FestivalQueenRanking;